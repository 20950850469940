import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { ApolloQueryResult, useLazyQuery } from '@apollo/client'
import { Editor, JSONContent } from '@tiptap/react'

import { uploadImage as uploadProjectImage } from 'components/shared/project/project-file/upload-card'
import { uploadImage as uploadProspectImage } from 'components/welcome/prospect/project-file/upload-file-card'
import useMeetingMutateNoContext from 'context/meeting/use-mutate-no-context'
import { useUniqueMeeting } from 'context/meeting/use-unique'
import { notifyError } from 'context/notifications/trigger'
import { useCommentNoteManyNoContext } from 'context/project/project-notes/use-many-no-context'
import useCommentNoteMutate from 'context/project/project-notes/use-mutate'
import { getCommentNotesWithTopic } from 'context/project/project-notes/utils-many'
import { parseStaff as parseProjectStaff } from 'context/project/utils'
import { parseStaff as parseProspectStaff } from 'context/prospect/utils'
import { FindOneMeetingPayload, FindOneMeetingVariables } from 'queries/meeting'
import {
  FIND_MANY_NOBILIA_OPTION,
  FindManyNobiliaOptionPayload,
  FindManyNobiliaOptionVariables,
} from 'queries/nobilia-option'
import {
  FindUniqueProjectPayload,
  FindUniqueProjectVariables,
  FIND_UNIQUE_PROJECT,
} from 'queries/project'
import {
  FIND_UNIQUE_PROSPECT,
  FindUniqueProspectPayload,
  FindUniqueProspectVariables,
} from 'queries/prospect'
import { CommentType } from 'types/comment'
import { CommentNote } from 'types/comment-note'
import { Meeting } from 'types/meeting'
import { NobiliaOption } from 'types/nobilia-option'
import { Project } from 'types/project'
import { ProjectFile, ProjectFileType } from 'types/project-file'
import { Prospect } from 'types/prospect'
import {
  SC_SESSION_STORAGE_KEY,
  SelectionCenterSessionStorage,
} from 'types/selection-center'
import { User } from 'types/user'
import { createRequiredContext } from 'utils'
import { getSessionStorage, setSessionStorage } from 'utils/session-storage'

import { useSelectionCenters } from '../provider'
import { useMeetingUserOrProspect } from '../use-meeting-user-or-prospect'

type SelectionCenterVisitContext = {
  //* Client info
  user: User | null
  prospect: Prospect | null
  clientName: string
  clientEmail: string
  isProspect: boolean

  //* Meeting/Project info
  catalog: string
  designer?: User | null
  designerNotes: CommentNote[] | null
  handleAttachProject: (newProject: Project) => Promise<void>
  handleRemoveProject: () => Promise<void>
  loadingMeeting: boolean
  loadingUpdateMeeting: boolean
  loadingVisitNotes: boolean
  meeting: Meeting | null
  project: Project | null
  loadingProject: boolean
  refetchMeeting: (
    variables?: Partial<FindOneMeetingVariables> | undefined,
  ) => Promise<ApolloQueryResult<FindOneMeetingPayload>>
  refetchVisitNotes: () => Promise<unknown>
  summaryNote: CommentNote | null

  //* Project Note
  handleSubmitNote: (noteData: {
    html: string
    json: JSONContent
    text: string
  }) => Promise<boolean>
  loadingSubmitNote: boolean
  noteAttachments: File[]
  openNotes: boolean
  richTextApi: Editor | null
  setNotesAttachments: React.Dispatch<React.SetStateAction<File[]>>
  setOpenNotes: React.Dispatch<React.SetStateAction<boolean>>
  setRichTextApi: React.Dispatch<React.SetStateAction<Editor | null>>
  isSummaryNotesSubmitted: boolean

  //* Finishes
  finishes: NobiliaOption[]
  removeFinish: (finish: NobiliaOption) => void
  addFinish: (finish: NobiliaOption) => void
  loadingNobiliaOptions: boolean
}

const [useSelectionCenterVisit, Provider] =
  createRequiredContext<SelectionCenterVisitContext>()

type SelectionCenterVisitProviderProps = {
  children: React.ReactNode
  meetingId: string
}

const SelectionCenterVisitProvider = ({
  children,
  meetingId,
}: SelectionCenterVisitProviderProps) => {
  //* STATES
  const { selectionCenter } = useSelectionCenters()
  const [openNotes, setOpenNotes] = useState(false)
  const catalog = '2024'
  const [project, setProject] = useState<Project | null>(null)
  const [finishes, setFinishes] = useState<NobiliaOption[]>([])
  const [richTextApi, setRichTextApi] = useState<Editor | null>(null)
  const [noteAttachments, setNotesAttachments] = useState<File[]>([])
  const [loadingSubmitNote, setLoadingSubmitNote] = useState(false)
  const [prospect, setProspect] = useState<Prospect | null>(null)

  //* QUERIES
  const {
    meeting,
    loading: loadingMeeting,
    refetch: refetchMeeting,
  } = useUniqueMeeting(meetingId ?? '')

  const [fetchNobiliaOptions, { loading: loadingNobiliaOptions }] =
    useLazyQuery<FindManyNobiliaOptionPayload, FindManyNobiliaOptionVariables>(
      FIND_MANY_NOBILIA_OPTION,
      {
        onCompleted: ({ nobiliaOptions }) => {
          setFinishes(nobiliaOptions)
        },
        onError: () => {
          setFinishes([])
        },
      },
    )

  const [fetchProject, { loading: loadingProject }] = useLazyQuery<
    FindUniqueProjectPayload,
    FindUniqueProjectVariables
  >(FIND_UNIQUE_PROJECT, {
    onCompleted: ({ project }) => {
      setProject(project ?? null)
    },
    onError: () => {
      setProject(null)
    },
  })

  const [fetchProspect] = useLazyQuery<
    FindUniqueProspectPayload,
    FindUniqueProspectVariables
  >(FIND_UNIQUE_PROSPECT, {
    onCompleted: ({ prospect }) => {
      setProspect(prospect ?? null)
    },
    onError: () => {
      setProspect(null)
    },
  })

  const {
    data: { commentNotes: visitNotes } = { commentNotes: null },
    refetch: refetchVisitNotes,
    loading: loadingVisitNotes,
  } = useCommentNoteManyNoContext({
    fetchPolicy: 'cache-and-network',
    variables: {
      orderBy: {
        createdAt: 'desc',
      },
      where: {
        meetingId: { equals: meetingId },
        type: { equals: CommentType.NOTES },
        replyOf: null,
      },
    },
  })

  const { updateMeeting, loadingUpdate: loadingUpdateMeeting } =
    useMeetingMutateNoContext()
  const { createOneCommentNote } = useCommentNoteMutate()

  const {
    clientEmail,
    clientName,
    isProspect,
    prospect: meetingProspect,
    user,
  } = useMeetingUserOrProspect(meeting)

  //* EFFECTS

  const designer = useMemo(() => {
    if (project) {
      return parseProjectStaff(project).primaryDesigner
    } else if (prospect) {
      return parseProspectStaff(prospect).designer
    }
    return null
  }, [project, prospect])

  useEffect(() => {
    if (!project?.id) {
      fetchProspect({
        variables: {
          where: {
            email: clientEmail,
          },
        },
      })
    } else {
      setProspect(null)
    }
  }, [project, clientEmail, fetchProspect])

  useEffect(() => {
    if (meeting?.project?.id) {
      fetchProject({
        variables: {
          where: {
            id: meeting.project.id,
          },
        },
      })
    } else {
      setProject(null)
    }
  }, [fetchProject, meeting])

  useEffect(() => {
    const summaryNote = visitNotes?.find((note) => note.data?.isSCVisitSummary)
    const currentSessionData = getSessionStorage<SelectionCenterSessionStorage>(
      SC_SESSION_STORAGE_KEY,
    )

    if (
      summaryNote?.data?.scSelectedFinishes?.length ||
      currentSessionData?.[meetingId]?.fronts?.length
    ) {
      const idArray = summaryNote?.data?.scSelectedFinishes?.length
        ? summaryNote?.data?.scSelectedFinishes
        : currentSessionData?.[meetingId]?.fronts

      fetchNobiliaOptions({
        variables: {
          where: {
            id: {
              in: idArray ?? [],
            },
          },
        },
      })
    }
  }, [visitNotes, fetchNobiliaOptions, meetingId])

  //* HANDLERS
  const addFinish = useCallback(
    (finish: NobiliaOption) => {
      const newFinishes = [...finishes, finish]

      setFinishes(newFinishes)
      //* Session Storage
      const currentData = getSessionStorage<SelectionCenterSessionStorage>(
        SC_SESSION_STORAGE_KEY,
      )
      if (!meetingId) return
      const newData: SelectionCenterSessionStorage = {
        ...currentData,
        [meetingId]: {
          ...currentData?.[meetingId],
          fronts: newFinishes.map((f) => f.id),
        },
      }
      setSessionStorage(SC_SESSION_STORAGE_KEY, newData)
    },
    [finishes, meetingId],
  )

  const removeFinish = useCallback(
    (finish: NobiliaOption) => {
      const newFinishes = finishes.filter((f) => f.id !== finish.id)
      setFinishes(newFinishes)
      //* Session Storage
      const currentData = getSessionStorage<SelectionCenterSessionStorage>(
        SC_SESSION_STORAGE_KEY,
      )
      if (!meetingId) return
      const newData: SelectionCenterSessionStorage = {
        ...currentData,
        [meetingId]: {
          ...currentData?.[meetingId],
          fronts: newFinishes.map((f) => f.id),
        },
      }
      setSessionStorage(SC_SESSION_STORAGE_KEY, newData)
    },
    [finishes, meetingId],
  )

  const handleRemoveProject = useCallback(async () => {
    if (!project) return
    try {
      await updateMeeting({
        variables: {
          where: {
            id: meeting?.id ?? '',
          },
          data: {
            project: {
              disconnect: true,
            },
          },
        },
      })
      setProject(null)
    } catch (error) {
      notifyError('Could not remove project')
      return
    }
    await refetchMeeting()
  }, [meeting?.id, project, refetchMeeting, updateMeeting])

  const handleAttachProject = useCallback(
    async (newProject: Project) => {
      try {
        await updateMeeting({
          variables: {
            where: {
              id: meeting?.id ?? '',
            },
            data: {
              project: {
                connect: {
                  id: newProject.id,
                },
              },
            },
          },
        })

        const res = await fetchProject({
          variables: {
            where: {
              id: newProject.id,
            },
          },
        })
        setProject(res.data?.project ?? null)
      } catch (error) {
        notifyError('Could not connect project')
        return
      }
      await refetchMeeting()
    },
    [meeting?.id, refetchMeeting, updateMeeting, fetchProject],
  )

  const handleSubmitNote = useCallback(
    async (noteData: { html: string; json: JSONContent; text: string }) => {
      if (!meeting?.id) return false

      setLoadingSubmitNote(true)

      let files: ProjectFile[] = []
      try {
        if (noteAttachments.length) {
          if (project) {
            files = await Promise.all(
              noteAttachments.map((a) =>
                uploadProjectImage({
                  fileType: ProjectFileType.COMMENT_ATTACHMENT,
                  imgFile: a,
                  projectId: project.id,
                }),
              ),
            )
          } else {
            files = await Promise.all(
              noteAttachments.map((a) =>
                uploadProspectImage({
                  fileType: ProjectFileType.COMMENT_ATTACHMENT,
                  imgFile: a,
                  prospectId: meetingProspect?.id ?? '',
                }),
              ),
            )
          }
        }
      } catch {
        notifyError('Could not upload attachments. Please try again.')
        setLoadingSubmitNote(false)
        return false
      }

      const success = await createOneCommentNote({
        variables: {
          data: {
            data: {
              isSCVisitSummary: true,
              scSelectedFinishes: finishes.map((f) => f.id),
            },
            bodyHtml: noteData.html,
            body: noteData.json,
            meeting: {
              connect: {
                id: meeting.id,
              },
            },
            title: `${selectionCenter?.name} Visit Note`,
            text: noteData.text,
            type: CommentType.NOTES,
            project: project ? { connect: { id: project.id } } : undefined,
            prospect: meetingProspect
              ? { connect: { id: meetingProspect.id } }
              : undefined,
            attachments: files.length
              ? { connect: files.map((f) => ({ id: f.id })) }
              : undefined,
          },
        },
      })

      if (!success) return false

      await refetchVisitNotes()
      setLoadingSubmitNote(false)
      const currentSessionData =
        getSessionStorage<SelectionCenterSessionStorage>(SC_SESSION_STORAGE_KEY)
      delete currentSessionData?.[meeting.id]
      setSessionStorage(SC_SESSION_STORAGE_KEY, currentSessionData)
      return true
    },
    [
      noteAttachments,
      project,
      meetingProspect,
      selectionCenter?.name,
      createOneCommentNote,
      meeting,
      finishes,
      refetchVisitNotes,
    ],
  )

  //* VALUE
  const value: SelectionCenterVisitContext = useMemo(() => {
    const visitNotesWithTopic = getCommentNotesWithTopic(visitNotes ?? [])
    const summaryNote =
      visitNotesWithTopic?.find((note) => note.data?.isSCVisitSummary) ?? null

    return {
      user,
      prospect,
      clientName,
      clientEmail,
      isProspect,

      catalog,
      designer,
      designerNotes:
        visitNotesWithTopic?.filter((note) => !note.data?.isSCVisitSummary) ??
        null,
      handleAttachProject,
      handleRemoveProject,
      loadingMeeting,
      loadingUpdateMeeting,
      loadingVisitNotes,
      meeting: meeting ?? null,
      project,
      loadingProject,
      refetchMeeting,
      refetchVisitNotes,
      summaryNote,

      handleSubmitNote,
      isSummaryNotesSubmitted: !!summaryNote,
      loadingSubmitNote,
      noteAttachments,
      openNotes,
      richTextApi,
      setNotesAttachments,
      setOpenNotes,
      setRichTextApi,

      finishes,
      removeFinish,
      addFinish,
      loadingNobiliaOptions,
    }
  }, [
    user,
    prospect,
    clientName,
    clientEmail,
    isProspect,
    designer,
    visitNotes,
    handleAttachProject,
    handleRemoveProject,
    loadingMeeting,
    loadingUpdateMeeting,
    loadingVisitNotes,
    meeting,
    project,
    loadingProject,
    refetchMeeting,
    refetchVisitNotes,
    handleSubmitNote,
    loadingSubmitNote,
    noteAttachments,
    openNotes,
    richTextApi,
    setNotesAttachments,
    setOpenNotes,
    setRichTextApi,
    finishes,
    removeFinish,
    addFinish,
    loadingNobiliaOptions,
  ])

  return <Provider value={value}>{children}</Provider>
}

export default SelectionCenterVisitProvider

export { useSelectionCenterVisit }
